import { getCurrentInstance, onUnmounted } from 'vue'
import { createDebugRecorder } from './debug-print'

/**
 * @typedef {import('@/node_modules/vue-router/types/index').default} VueRouter
 * @typedef {import('@/node_modules/vue-router/types/index').Route} VueRoute
 */

/** 访问 vuex */
export const useStore = () => {
  const vm = getCurrentInstance()
  if (!vm) throw new Error('must be called in setup')
  return vm.proxy.$store
}

/** 
 * 访问 router
 * @returns {VueRouter}
 */
export const useRouter = () => {
  const vm = getCurrentInstance()
  if (!vm) throw new Error('must be called in setup')
  return vm.proxy.$router
}

/** 
 * 访问 route
 * @returns {VueRoute}
 */
export const useRoute = () => {
  const vm = getCurrentInstance()
  if (!vm) throw new Error('must be called in setup')
  return vm.proxy.$route
}

export const useDebugRecorder = (tagName) => {
  const debugRecorder = createDebugRecorder(tagName)
  onUnmounted(() => {
    debugRecorder.dispose()
  })
  return debugRecorder
}
