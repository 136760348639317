/* eslint-disable no-unused-vars */
import { ref, computed, toRefs, onDeactivated, onActivated, nextTick } from 'vue'
import { useSbcSwiper } from '@modules/homepage/ccc-stuff/sbc/use-swiper'
import { distributeItemsToSlides } from '@modules/homepage/ccc-stuff/sbc/distribute'
import { reportSbcHidden } from '@modules/homepage/ccc-stuff/sbc/report-sbc-hidden'
import { useHomeSlsLog, SLS_LOG_LEVEL } from '@modules/homepage/composables/use-home-sls-log'
import { setupRowsAndColsCountByDistributionInStyleZ } from '@modules/homepage/ccc-stuff/sbc/setup-info-summary'
import { transformImg } from '@shein/common-function'
import { useDebugRecorder } from '@modules/homepage/utils/hooks'
import { handleStartCollectData, handleEndCollectData } from 'public/src/pages/common/userActionTracker/core/index.js'
import { getImageRefreshedSbcEntryItems } from 'public/src/services/api/ccc'
import { SBC_ENTRY_FOLLOW_FEATURE_KEYS } from '@modules/homepage/ccc-stuff/sbc/constants'

/**
 * @typedef {import('@modules/homepage/types/sbc').SbcPlaceholderInfoSummary} SbcPlaceholderInfoSummary
 * @typedef {import('@modules/homepage/types/sbc').SbcPlaceholderDataItem} SbcPlaceholderDataItem
 * @typedef {import('@modules/homepage/types/sbc').SbcPlaceholderMetaData} SbcPlaceholderMetaData
 * @typedef {import('@modules/homepage/types/sbc').SbcPlaceholderInfoSummary} SbcPlaceholderInfoSummary
 */

const DEFAULT_SBC_ENTRY_IMAGE_REFRESH_GAP = 40
const isAnimationImageRegExp = /\.(gif)$/

function reportFieldMissing({
  propData,
  sceneData,
  index,
  fieldName,
}) {
  // 发送日志报告异常
  useHomeSlsLog({
    logLevel: SLS_LOG_LEVEL.ERROR,
    tag: 'client_home_error_total',
    message: 'CCCShownFilterError',
    reason: `items 中存在没有 ${fieldName} 字段的数据项，请关注！`,
  }, {
    propData,
    sceneData,
    index
  })
}
function preHandleForSbcDataItem({
  propData,
  sceneData,
  index,
  item,
}) {
  if (item.image?.src) {
    // 常规图片类型，让 BaseImg 处理转为 webp、并裁剪
    // 动态图保持原样不动、避免图花掉
    if (isAnimationImageRegExp.test(item.image.src)) {
      item._FE_isUnCut_ = 0
      item._FE_isAnimationImg_ = 1
    }
  } else {
    // 没有分类图，发送日志报告异常
    reportFieldMissing({
      propData,
      sceneData,
      index,
      fieldName: item.image ? 'image.src' : 'image',
    })
  }
  
  if (!item.smallTitle) {
    // 没有分类标题，发送日志报告异常
    reportFieldMissing({
      propData,
      sceneData,
      index,
      fieldName: 'smallTitle'
    })
  }
}

/**
 * 获取 SBC 数据项的图片 hash
 * 
 * @param {SbcPlaceholderDataItem} item 
 */
function getSbcDataItemImageHash(item) {
  const imgUrl = item.image.src
  if (!imgUrl) return ''

  const imgFileName = imgUrl.split('/').pop()
  // 去掉扩展名
  const imgHash = imgFileName.split('.')[0]
  return imgHash
}

/** 从 Vue 组件的 props 中提取数据 */
function deriveFromVueProps(vueProps) {
  const {
    propData: contentData,
    sceneData: sceneDataFromCommonProps,
    index: componentIndexInPage,
    context: contextFromCommonProps,
  } = toRefs(vueProps)
  const compProps = computed(() => (contentData.value.props || {}))
  const compMetaData = computed(() => (
    compProps.value.metaData || {}
  ))
  const compExtendData = computed(() => (
    sceneDataFromCommonProps.value.extendData 
    || contextFromCommonProps.value?.content?.extendParse 
    || {}
  ))

  const dataList = (compProps.value?.items || [])
  // 数据项需要预处理，比如加上一些提示信息字段
  dataList.forEach((item) => preHandleForSbcDataItem({
    propData: vueProps.propData,
    sceneData: vueProps.sceneData,
    index: vueProps.index,
    item,
  }))
  const itemsDataList = ref(dataList)

  return {
    contentData,
    sceneDataFromCommonProps,
    contextFromCommonProps,
    compProps,
    compMetaData,
    compExtendData,
    itemsDataList,
    componentIndexInPage,
  }
}

/**
 * 获取元素卡片样式
 * 
 * @param {{
*   compMetaData: import('vue').ComputedRef<SbcPlaceholderMetaData>
* }} params
*/
function useCardStyle({ compMetaData }) {
  // 卡片化样式
  const isCardStyle = computed(() => {
    const {
      isCardShow = '0',
      margin: marginList = [],
      cardRadius = '0',
    } = compMetaData.value || {}

    if (isCardShow == '0') return false
    if (isCardShow == '1' || Number(cardRadius) > 0) return true

    return (
      Array.isArray(marginList) 
      && marginList.some((item) => item > 0)
    )
  })
  const cardStyle = computed(() => {
    const { margin = [], cardRadius = '0' } = compMetaData.value || {}
    const marginList = (
      Array.isArray(margin) && margin.length === 4
        ? margin.slice()
        : Array(4).fill(0)
    )
    const marginStyle = (
      marginList
        .map((item) => `${Number(item)}px`)
        .join(' ')
    )
    const styleResult = {
      margin: marginStyle,
      overflow: 'hidden',
      borderRadius: `${cardRadius}px`,
    }

    return isCardStyle.value ? styleResult : {}
  })

  return cardStyle
}

/**
 * 获取根元素样式
 * 
 * @param {{
 *   isSkeletonShow: import('vue').Ref<boolean>
 *   compMetaData: import('vue').ComputedRef<SbcPlaceholderMetaData>
 * }} params
 */
function useRootStyle({
  isSkeletonShow,
  compMetaData,
}) {
  // 卡片化样式
  const cardStyle = useCardStyle({ compMetaData })

  // 背景图片样式
  const bgImgStyle = computed(() => {
    const bgImgSrc = compMetaData.value?.bgImage?.src
    if(bgImgSrc){
      return {
        backgroundSize: '100% auto', // prd 要求背景图片宽度100%，高度自适应
        backgroundImage: `url(${transformImg({ img: bgImgSrc })})`
      }
    }
    return { background: '#fff' } 
  })

  // 组件根元素样式
  const rootStyle = computed(() => {
    return {
      width: '100%',
      paddingTop: (
        compMetaData.value.showTopSpacing
          ? '8px'
          : '0'
      ),
      visibility: (
        isSkeletonShow.value
          ? 'hidden'
          : 'visible'
      ),
      ...cardStyle.value,
      ...bgImgStyle.value,
    }
  })

  return rootStyle
}

/**
 * 初始化 SBC 基本信息
 * 
 * @param {{
 *   contentData: import('vue').Ref<any>,
 *   sceneDataFromCommonProps: import('vue').Ref<any>,
 *   componentIndexInPage: import('vue').Ref<number>,
 *   sbcInfoSummary: import('vue').Ref<SbcPlaceholderInfoSummary>,
 *   compMetaData: import('vue').ComputedRef<SbcPlaceholderMetaData>,
 *   itemsDataList: import('vue').ComputedRef<SbcDataItem[]>,
 *   isShow: import('vue').Ref<boolean>
 * }} params
 */
function setupSbcInfoSummary({
  contentData,
  sceneDataFromCommonProps,
  componentIndexInPage,
  sbcInfoSummary, 
  compMetaData, 
  itemsDataList, 
  isShow,
}) {
  const {
    sbcAutoSlide,
    sbcSlideSpeed,
    sbcDistribution,
    sbcFontSize,
    sbcFontThickness,
    sbcIconImageShape,
    sbcLines,
    sbcSpacing,
    sbcStroke,
    sbcIconRefreshCnt = '40'
  } = compMetaData.value.contentExtra || {}
  
  sbcInfoSummary.value.fontSize = sbcFontSize || '11'
  sbcInfoSummary.value.fontThickness = sbcFontThickness || 'Regular'
  sbcInfoSummary.value.textLines = sbcLines || '2'
  sbcInfoSummary.value.stroke = sbcStroke || 'false'
  
  let rowCount = compMetaData.value?.rows || 1
  const listLength = itemsDataList.value.length

  if (listLength < 5) {
    // 不满一行，不显示本组件
    isShow.value = false
    reportSbcHidden({
      vuePropsPropData: contentData,
      vuePropsSceneData: sceneDataFromCommonProps,
      vuePropsIndex: componentIndexInPage,
    })
  }

  // 设置列数
  if (sbcDistribution === 'N') {
    // 按 N 字型排列，列数为 总数除以行数
    sbcInfoSummary.value.columnCount = Math.ceil(listLength / rowCount)
  } else {
    // 按 Z 字形式排布，sbcDistribution 的值为数多少个折行: '4' | '5' ...
    const counts = setupRowsAndColsCountByDistributionInStyleZ({
      sbcDistribution,
      listLength,
      rowCount
    })
    rowCount = counts.rowCount
    sbcInfoSummary.value.columnCount = counts.columnCount
  }

  // 设置行数
  sbcInfoSummary.value.rowCount = rowCount

  // 设置排布方式
  sbcInfoSummary.value.distribution = sbcDistribution || '4'

  // 设置一行几列样式
  sbcInfoSummary.value.spacing = sbcSpacing || '4.7' 

  // 设置是否自动滑动
  sbcInfoSummary.value.isAutoSlide = sbcAutoSlide && (
    typeof sbcAutoSlide === 'boolean'
      ? sbcAutoSlide
      : typeof sbcAutoSlide === 'string'
        ? sbcAutoSlide !== 'false'
        : false
  )
  // 设置自动滑动delay
  sbcInfoSummary.value.sbcAutoSlide = sbcAutoSlide

  // 设置自动滑动速度
  sbcInfoSummary.value.sbcSlideSpeed = sbcSlideSpeed

  // 设置图片形状
  sbcInfoSummary.value.imageShape = sbcIconImageShape || 'circle'

  // 设置入口图刷新频率时间间隔
  const iconRefreshTimeGap = Number(sbcIconRefreshCnt) 
  sbcInfoSummary.value.iconRefreshTimeGap = (
    Number.isNaN(iconRefreshTimeGap) 
      ? DEFAULT_SBC_ENTRY_IMAGE_REFRESH_GAP 
      : iconRefreshTimeGap
  )
}

function customizedLeadingDebounce({ func, wait = 0, options = {} }) {
  const { onNotCall } = options
  let timerId
  let result
  let context

  const debounced = function(...args) {
    return new Promise(resolve => {
      context = this
      if (timerId) {
        clearTimeout(timerId)
      }
  
      // Must leading, call once immediately
      const callNow = !timerId
      timerId = setTimeout(() => {
        timerId = null
      }, wait)
  
      if (callNow) {
        result = func.apply(context, args)
        resolve(result)
      } else {
        onNotCall?.()
      }
    })
  }

  debounced.cancel = function () {
    if (timerId) {
      clearTimeout(timerId)
    }
    timerId = context = null
  }
  
  return debounced
}

export function useSbcPlaceholderStore(vueProps) {
  const {
    contentData,
    sceneDataFromCommonProps,
    componentIndexInPage,
    compMetaData,
    compExtendData,
    itemsDataList,
  } = deriveFromVueProps(vueProps)

  const debugRecorder = useDebugRecorder('category-recommend-placeholder')

  /** 是否展示组件 */
  const isShow = ref(true)

  /** 是否展示骨架屏 */
  const isSkeletonShow = ref(false)

  /** 图片样式 */
  const imageStyle = computed(() => ({
    backgroundColor: compMetaData.value.transparentBackgroundColor || '#F0EEEC',
  }))

  /** 腰带样式 */
  const beltStyle = computed(() => ({
    backgroundColor: compMetaData.value.beltBackgroundColor || '#FFBD58',
    color: compMetaData.value.beltTextColor || '#000000',
  }))
  const isShowBelt = computed(() => Boolean(compMetaData.value.showBelt === '1'))

  /** 
   * 入口单元格数据
   * @type {import('vue').Ref<SbcDataItem[][]>}
   */
  const slidesData = ref([])

  /** 
   * swiper 虚拟渲染数据
   * @type {import('vue').Ref<SbcDataItem[][]>
   * */
  const virtualData = ref([])

  /** swiper 初始化渲染条数 */
  const initVirtualNum = ref(5)

  // ... 其他杂项状态

  const isFirstPage = computed(() => (
    Boolean(contentData.value?.isFirstPage)
  ))
  const lineClampClass = computed(() => {
    const { textLines } = sbcInfoSummary.value
    return (
      textLines === '3'
        ? 'three-lines'
        : 'two-lines'
    )
  })
  const cateNameStyle = computed(() => {
    const {
      cateNameColor,
      iconBottomColor,
    } = compMetaData.value
    const {
      fontSize = '11',
      fontThickness = 'Regular',
    } = sbcInfoSummary.value

    return {
      fontSize: `${fontSize}px`,
      fontWeight: fontThickness === 'Medium' ? '500' : '400',
      color: cateNameColor,
      iconBottomColor,
    }
  })
  const gridCount = computed(() => {
    const {
      sbcDistribution,
      rowCount,
    } = sbcInfoSummary.value
  
    if (
      sbcDistribution === 'N'
    ) {
      // N 字型排列，不需要计算 grid
      return
    }
    
    const oneGridCols = sbcDistribution ? Number(sbcDistribution) : 4
    return Math.ceil(itemsDataList.value.length / (rowCount * oneGridCols))
  })

  // #region 初始化数据

  /** @type {import('vue').Ref<SbcPlaceholderInfoSummary>} */
  const sbcInfoSummary = ref({
    rowCount: 1,
    columnCount: 4,
    distribution: '4',
    spacing: '4.7',
    fontSize: '11',
    fontThickness: 'Regular',
    textLines: '2',
    isAutoSlide: false,
    sbcSlideSpeed: 20,
    sbcAutoSlide: false,
    imageShape: 'circle',
    iconRefreshTimeGap: 40, // 单位：秒
  })
  
  // -- 1. 初始化 SBC 信息
  setupSbcInfoSummary({
    contentData,
    sceneDataFromCommonProps,
    componentIndexInPage,
    sbcInfoSummary,
    compMetaData,
    itemsDataList,
    isShow,
  })

  // -- 2. 接着处理好 slidesData 给 Vue 渲染 swiper slide 时排布
  distributeItemsToSlides({
    sbcInfoSummary,
    itemsDataList,
    slidesData,
  })

  // -- 3. 再完成 Swiper 配置
  const swiperComposables = useSbcSwiper({
    sbcInfoSummary,
    sceneData: sceneDataFromCommonProps,
    slidesData,
    virtualData,
    gridCount,
    debugRecorder,
  }, {
    enableScrollBar: true,
    classNamePrefix: 'category-recommend-placeholder',
    scrollBarHorizontalClass: 'category-recommend-placeholder__swiper-scrollbar-horizontal',
    scrollBarDragClass: 'category-recommend-placeholder__swiper-scrollbar-drag',
    onReturnVisible: () => {
      // debugLog('Swiper 恢复可见，重新初始化')
      // 从其他页面返回激活组件后，Swiper 初始化完再显示组件
      isSkeletonShow.value = false
    }
  })

  // -- 4. 从排布好的 swiper slide 切出初始渲染数据
  virtualData.value = slidesData.value.slice(0, initVirtualNum.value)

  // #endregion

  /** 组件根元素样式 */
  const rootStyle = useRootStyle({
    isSkeletonShow,
    compExtendData,
    compMetaData,
  })

  onDeactivated(() => {
    // 当组件失活后用骨架屏盖住
    if (requestIdleCallback) {
      requestIdleCallback(() => {
        isSkeletonShow.value = true
      })
    } else {
      setTimeout(() => {
        isSkeletonShow.value = true
      }, 100)
    }
  })

  const markingSbcEntryIndexTuple = ref(null)
  const markingSbcEntryItem = computed(() => {
    if (!markingSbcEntryIndexTuple.value) return null

    const { colIndex, rowIndex } = markingSbcEntryIndexTuple.value
    return slidesData.value[colIndex][rowIndex] ?? null
  })
  const startSbcEntryFollowActionTrack = ({
    colIndex,
    rowIndex,
  }) => {
    // 通过 slidesData.value[colIndex][rowIndex] 获取当前点击的数据项
    markingSbcEntryIndexTuple.value = { colIndex, rowIndex }
    // 标记 sbc 入口被点击、跳转去了列表页
    markingSbcEntryItem.value.isJumpList = '1'

    // 开始收集 点击列表商品、加购物车 的行为
    handleStartCollectData(SBC_ENTRY_FOLLOW_FEATURE_KEYS.GOODS_LIST_CLICK)
    handleStartCollectData(SBC_ENTRY_FOLLOW_FEATURE_KEYS.ADD_TO_CART)
  }
  const endSbcEntryFollowActionTrack = async () => {
    if (!markingSbcEntryItem.value) {
      return
    }

    markingSbcEntryItem.value.isJumpCart = handleEndCollectData(SBC_ENTRY_FOLLOW_FEATURE_KEYS.ADD_TO_CART)
    markingSbcEntryItem.value.isProduceClick = handleEndCollectData(SBC_ENTRY_FOLLOW_FEATURE_KEYS.GOODS_LIST_CLICK)

    // 发送请求到中间层刷新入口图，获取到新的 sbc items
    const { code, info } = await requestRefreshSbcEntryImage({
      abt: new URLSearchParams(window.location.search).get('sbcIconRefreshDebugAbt'),
      props: {
        items: [
          ...itemsDataList.value,
        ]
      }
    })
    if (
      code !== '0'
      || !info
      || !Array.isArray(info.items)
    ) {
      return
    }

    // 更新 slidesData
    const newItemsDataList = info.items
    newItemsDataList.forEach(item => preHandleForSbcDataItem({
      propData: contentData,
      sceneData: sceneDataFromCommonProps,
      index: componentIndexInPage,
      item,
    }))
    
    // eslint-disable-next-line require-atomic-updates
    itemsDataList.value = newItemsDataList

    distributeItemsToSlides({
      sbcInfoSummary,
      itemsDataList,
      slidesData,
    })

    markingSbcEntryIndexTuple.value = null
  }
  /**
   * 请求中间层，可能刷新 SBC 入口图
   * 由于性能考量，需要限制请求频率
   */
  const requestRefreshSbcEntryImage = customizedLeadingDebounce({
    func: getImageRefreshedSbcEntryItems,
    wait: sbcInfoSummary.value.iconRefreshTimeGap * 1e3,
    options: {
      onNotCall: () => {
        // 请求被时间间隔阻断，需清除行为分析记录
        if (!markingSbcEntryItem.value) return
        delete markingSbcEntryItem.value.isJumpList
        delete markingSbcEntryItem.value.isJumpCart
        delete markingSbcEntryItem.value.isProduceClick
      }
    }
  })

  onActivated(async () => {
    await endSbcEntryFollowActionTrack()
  })

  return {
    isSkeletonShow,
    isShow,
    isFirstPage,
    isShowBelt,
    sbcInfoSummary,
    compMetaData,
    slidesData,
    virtualData,
    rootStyle,
    cateNameStyle,
    imageStyle,
    beltStyle,
    lineClampClass,
    ...swiperComposables,
    startSbcEntryFollowActionTrack,
    getSbcDataItemImageHash,
  }
}
