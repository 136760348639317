var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"category-recommend-placeholder__container"},[_c('SbcSkeleton',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSkeletonShow),expression:"isSkeletonShow"}]}),_vm._v(" "),(_vm.isShow && _vm.slidesData.length > 0)?_c('div',{staticClass:"category-recommend-placeholder",style:(_vm.rootStyle),attrs:{"data-background-image":_vm.transformImg({ img: _vm.compMetaData?.backgroundImg?.src })}},[(_vm.compMetaData.titleText)?_c('header',{staticClass:"category-recommend-placeholder__header",style:({ 'color': _vm.compMetaData.titleColor })},[_vm._v("\n      "+_vm._s(_vm.compMetaData.titleText || '')+"\n    ")]):_vm._e(),_vm._v(" "),_c('swiper-container',{ref:"swiperInstanceRef",class:_vm.swiperContainerClassName,attrs:{"init":"false"}},[_c('div',{staticClass:"category-recommend-placeholder__hydration-layer"},_vm._l((_vm.virtualData),function(col,colIndex){return _c('swiper-slide',{key:colIndex,staticClass:"category-recommend-placeholder__slide",class:{
            'category-recommend-placeholder__nocutImg': _vm.sbcInfoSummary.imageShape === 'nocut',
            'is-need-scrollbar': Number(_vm.sbcInfoSummary.spacing) % 1 === 0 // 一屏为整数列时，需要滚动条
          },style:({ width: _vm.swiperSlideWidth })},[_vm._l((col),function(item,rowIndex){return [(item)?_c('div',{key:`${rowIndex + 1}_${colIndex + 1}`,staticClass:"category-recommend-placeholder__item",staticStyle:{"position":"relative"},attrs:{"data-eid":`category-recommend-placeholder-${rowIndex}-${colIndex}-${_vm.getSbcDataItemImageHash(item)}`,"data-loc-tag":item._FE_itemLoc_,"data-distribution-loc":item._FE_DistributionLoc_},on:{"click":function($event){return _vm.handleClickItem(item, colIndex, rowIndex)}}},[(item.image)?_c('BaseImg',{directives:[{name:"tap",rawName:"v-tap",value:(_vm.formatAnalysisData('2-22-2', { item, rowIndex, colIndex, if_auto_slide: _vm.isAutoPlayStarted })),expression:"formatAnalysisData('2-22-2', { item, rowIndex, colIndex, if_auto_slide: isAutoPlayStarted })"},{name:"expose",rawName:"v-expose",value:(_vm.formatAnalysisData('2-22-1', { item, rowIndex, colIndex, if_auto_slide: _vm.isAutoPlayStarted })),expression:"formatAnalysisData('2-22-1', { item, rowIndex, colIndex, if_auto_slide: isAutoPlayStarted })"}],class:{ 
                  'category-recommend-placeholder__circularImg': _vm.sbcInfoSummary.imageShape === 'circle',
                  'category-recommend-placeholder__nocutImg': _vm.sbcInfoSummary.imageShape === 'nocut',
                  'category-recommend-placeholder__nonNoCut': _vm.sbcInfoSummary.imageShape !== 'nocut',
                  'category-recommend-placeholder__oval': _vm.sbcInfoSummary.imageShape === 'oval',
                  'category-recommend-placeholder__stroke': _vm.sbcInfoSummary.stroke === 'true',
                  'not-fsp-element': item._FE_isAnimationImg_,
                },style:(_vm.imageStyle),attrs:{"brand":_vm.brand,"img-src":item.image.src,"placeholder":{
                  width: _vm.sbcInfoSummary.imageShape === 'nocut' ? 76 : 58,
                  height: _vm.sbcInfoSummary.imageShape === 'nocut' ? 56 : 58,
                },"first-screen":_vm.isFirstPage && (colIndex < 5),"imgDesignWidth":144,"comp-src":'category-recommend-placeholder',"ratio":_vm.sbcInfoSummary.imageShape === 'nocut' ? 1.357 : 1,"fit":"cover","imgDataExp":{
                  unCut: item._FE_isUnCut_,
                }}}):_vm._e(),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowBelt),expression:"isShowBelt"}],staticClass:"category-recommend-placeholder__item-belt",style:(_vm.beltStyle)},[_vm._v("\n                "+_vm._s(item.beltText || '')+"\n              ")]),_vm._v(" "),_c('div',{staticClass:"category-recommend-placeholder__name",class:_vm.lineClampClass,style:(_vm.cateNameStyle)},[_vm._v("\n                "+_vm._s(item.smallTitle || '')+"\n              ")])],1):_vm._e()]})],2)}),1)])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }