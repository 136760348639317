<script>
import SbcSkeleton from '@modules/homepage/ccc-stuff/sbc/components/sbc-skeleton.vue'
import BaseImg from 'public/src/pages/components/ccc/base/BaseImg'
import mixin from 'public/src/pages/components/ccc/components/mixins/mixin'
import { commonProps } from 'public/src/pages/components/ccc/common/common-props.js'
import { useSbcPlaceholderStore } from './store'
import { transformImg } from '@shein/common-function'

export default {
  name: 'CategoryRecommendPlaceholder',
  components: {
    SbcSkeleton,
    BaseImg,
  },
  mixins: [mixin],
  props: commonProps,
  setup(props) {
    return {
      ...useSbcPlaceholderStore(props)
    }
  },
  methods: {
    transformImg,
    handleClickItem(item, colIndex, rowIndex) {
      // 开始追踪进入 SBC 入口后的行为（列表点击、加车...）
      this.startSbcEntryFollowActionTrack({
        colIndex,
        rowIndex,
      })
      
      const { _FE_itemLoc_, webClickUrl, ...itemFields } = item
      this.handleClickToPage({
        item_loc: _FE_itemLoc_,
        webClickUrl,
        isBff: true,
        ...itemFields
      }, false)
    },
    formatAnalysisData(evtKey, { item: dataItem, if_auto_slide }) {
      const {
        _FE_itemLoc_: item_loc,
        hrefTarget,
        hrefType,
      } = dataItem

      const customParams = {}
      if (typeof window !== 'undefined') {
        customParams.src_tab_page_id = (
          window.getSaPageInfo?.tab_page_id 
          || (window.SaPageInfo ? (SaPageInfo.page_name + SaPageInfo.start_time) : '')
        )
      }

      return this.getAnalysisData(evtKey, { 
        item: {
          ...dataItem,
          rec_mark: 1,
          item_loc,
          hrefTarget,
          hrefType,
          if_auto_slide,
        },
        index: this.index,
        customParams,
        useBffApi: true,
      })
    },
  }
}
</script>

<template>
  <div class="category-recommend-placeholder__container">
    <SbcSkeleton v-show="isSkeletonShow" />
    <div 
      v-if="isShow && slidesData.length > 0"
      class="category-recommend-placeholder"
      :style="rootStyle"
      :data-background-image="transformImg({ img: compMetaData?.backgroundImg?.src })"
    >
      <header
        v-if="compMetaData.titleText"
        class="category-recommend-placeholder__header"
        :style="{ 'color': compMetaData.titleColor }" 
      >
        {{ compMetaData.titleText || '' }}
      </header>
      <swiper-container
        ref="swiperInstanceRef"
        init="false"
        :class="swiperContainerClassName"
      >
        <div class="category-recommend-placeholder__hydration-layer">
          <swiper-slide
            v-for="(col, colIndex) in virtualData"
            :key="colIndex"
            class="category-recommend-placeholder__slide"
            :class="{
              'category-recommend-placeholder__nocutImg': sbcInfoSummary.imageShape === 'nocut',
              'is-need-scrollbar': Number(sbcInfoSummary.spacing) % 1 === 0 // 一屏为整数列时，需要滚动条
            }"
            :style="{ width: swiperSlideWidth }"
          >
            <template v-for="(item, rowIndex) in col">
              <div
                v-if="item"
                :key="`${rowIndex + 1}_${colIndex + 1}`"
                style="position: relative;"
                class="category-recommend-placeholder__item"
                :data-eid="`category-recommend-placeholder-${rowIndex}-${colIndex}-${getSbcDataItemImageHash(item)}`"
                :data-loc-tag="item._FE_itemLoc_"
                :data-distribution-loc="item._FE_DistributionLoc_"
                @click="handleClickItem(item, colIndex, rowIndex)"
              >
                <!-- 图片 -->
                <BaseImg 
                  v-if="item.image"
                  v-tap="formatAnalysisData('2-22-2', { item, rowIndex, colIndex, if_auto_slide: isAutoPlayStarted })"
                  v-expose="formatAnalysisData('2-22-1', { item, rowIndex, colIndex, if_auto_slide: isAutoPlayStarted })"
                  :class="{ 
                    'category-recommend-placeholder__circularImg': sbcInfoSummary.imageShape === 'circle',
                    'category-recommend-placeholder__nocutImg': sbcInfoSummary.imageShape === 'nocut',
                    'category-recommend-placeholder__nonNoCut': sbcInfoSummary.imageShape !== 'nocut',
                    'category-recommend-placeholder__oval': sbcInfoSummary.imageShape === 'oval',
                    'category-recommend-placeholder__stroke': sbcInfoSummary.stroke === 'true',
                    'not-fsp-element': item._FE_isAnimationImg_,
                  }"
                  :style="imageStyle"
                  :brand="brand" 
                  :img-src="item.image.src" 
                  :placeholder="{
                    width: sbcInfoSummary.imageShape === 'nocut' ? 76 : 58,
                    height: sbcInfoSummary.imageShape === 'nocut' ? 56 : 58,
                  }"
                  :first-screen="isFirstPage && (colIndex < 5)"
                  :imgDesignWidth="144"
                  :comp-src="'category-recommend-placeholder'"
                  :ratio="sbcInfoSummary.imageShape === 'nocut' ? 1.357 : 1"
                  fit="cover"
                  :imgDataExp="{
                    unCut: item._FE_isUnCut_,
                  }"
                />

                <!-- 腰带 -->
                <div
                  v-show="isShowBelt"
                  class="category-recommend-placeholder__item-belt"
                  :style="beltStyle"
                >
                  {{ item.beltText || '' }}
                </div>

                <!-- 入口名称 -->
                <div
                  :class="lineClampClass"
                  :style="cateNameStyle"
                  class="category-recommend-placeholder__name"
                >
                  {{ item.smallTitle || '' }}
                </div>
              </div>
            </template>
          </swiper-slide>
        </div>
      </swiper-container>
    </div>
  </div>
</template>

<style lang="less" scoped>
/* stylelint-disable selector-class-pattern */
@vw: 375/100vw;

.category-recommend-placeholder__container {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
}

.category-recommend-placeholder {
  
  &__header {
    
    .line-camp();

    margin: 8 / @vw 12 / @vw;
    font-size: 13px;
    font-weight: bolder;
    color: @sui_color_gray_dark1;
    font-weight: 600;
    line-height: 16px;
  }

  &__item {
    min-height: 90px;
    margin-top: 4px;

    &[data-eid^="category-recommend-placeholder-0"] {
      margin-top: 0;
    }

    &-belt {
      min-width: 56 / @vw;
      position: absolute;
      border-radius: 7px;
      min-height: 11px;
      font-size: 9px;
      font-weight: 400;
      line-height: normal;
      padding: 1px 4px;
      transform: translateY(-100%) translate(-50%);
      left: 50%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;

      // 默认背景色和文字颜色
      background-color: #FFBD58;
      color: #000000;
    }
  }

  &__swiper {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    padding-left: 0;

    .swiper-slide {
      margin-right: 2 / @vw; /* 卡片左右之间间距为2dp */

      &:first-child {
        margin-top: 8 / @vw; /* 最上面的卡片顶部8dp */
      }

      &:last-child {
        margin-bottom: 4 / @vw; /* 最下面间距4dp */
      }
    }
  }

  &__hydration-layer {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    padding-left: 0;
  }

  &__slide {
    display: flex;
    flex-direction: column;
    position: relative;
    flex-shrink: 0;

    @margin: 8 / @vw;
    height: calc(100% - @margin - @margin);
    margin-top: @margin;
    margin-bottom: @margin;
    
    &.is-need-scrollbar {
      margin-bottom: 7px + (4 / @vw);
    }

    .base-img {
      width: 58 / @vw;
      margin: 0 auto;

      &.category-recommend-placeholder__nocutImg {
        width: 76 / @vw;
        background-color: unset;
      }
    }

  }

  &__name {
    font-family: Helvetica;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    word-wrap: break-word;
    text-align: center;
    margin-top: 4 / @vw;
    margin-left: 4 / @vw;
    margin-right: 4 / @vw;

    .line-camp();

    &.two-lines {
      height: 26px;
      display: -webkit-box;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    &.three-lines {
      height: 40px;
      display: -webkit-box;
      line-clamp: 3;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  &__circularImg {
    border-radius: 50%;
  }

  &__oval {
    @oval_rotate_base_num: 16;
    @oval_rotate: ~"-@{oval_rotate_base_num}deg";
    @oval_rotate_back : ~"@{oval_rotate_base_num}deg";

    @oval_base_num: 15;
    @lower_part: ~"@{oval_lower_part}%";
    @upper_part: ~"@{oval_upper_part}%";
    @oval_lower_part: 65 - @oval_base_num;
    @oval_upper_part: 65 + @oval_base_num;

    transform: rotate(@oval_rotate);
    border-radius: ~"@{lower_part} @{upper_part} / @{upper_part} @{lower_part};";

    & > * {
      transform: rotate(@oval_rotate_back); // 转回来
    }
  }

  &__nonNoCut {
    max-width: 84px;
  }

  &__stroke {
    border: 0.5px solid rgba(34, 34, 34, 0.1);
  }

}
</style>
