import { ref, computed, nextTick, onActivated, onDeactivated, onMounted, onUnmounted, onBeforeMount, watch } from 'vue'
import { Grid, Autoplay, FreeMode, Virtual, Scrollbar } from 'swiper/modules'
import { swiperStartAutoPlay, swiperStopAutoPlay } from 'public/src/pages/components/ccc/common/swiper.js' // swiper 实例方法
import expose from 'public/src/services/expose/index.js'
import { cccxEventBus } from 'public/src/pages/components/ccc/common/utils.js'
import { deriveFromGlobalCommonInfo } from '../../utils/derive-from-gb-common-info'

const swiperFreeModeWrapperStyle = `
  .swiper-free-mode > .swiper-wrapper {
    transition-timing-function : linear;
  }
`
const staticSwiperParams = {
  spaceBetween: 0,
  slidesPerGroup: 1,
  freeMode: {
    enabled: true,
    sticky: false,
    momentum: true,
    momentumBounce: false,
  },
  shortSwipes: false, // 禁止短距离快速滑动
  resistance: true,
  resistanceRatio: 0.1, // 滑动屏幕阻力，越小阻力越大
  touchRatio: 2, // 对于移动设备，屏幕滑动的速度与鼠标移动的速度之比
  autoplay: {
    // 开启自动滑动
    delay: 1, 
    disableOnInteraction: true, // 禁止拖拽后继续自动滑动
    stopOnLastSlide: true,
  },
  autoplayOutsideControl: true, // 是否外部控制 swiper 的自动轮播开关
}

/**
 * Swiper 滚动条样式
 * 
 * @param {{
 *   classNamePrefix: string
 *   gridCount: import('vue').Ref<number>
 * }} param
 * @returns 
 */
const swiperScrollBarStyle = ({ 
  gridCount,
  classNamePrefix
}) => `
  .${classNamePrefix}__swiper-scrollbar-horizontal {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 4px;
    top: auto;
    z-index: 50;
    height: 3px;
    width: calc(${gridCount.value} * 12px);
    background: #E5E5E5;
  }

  .${classNamePrefix}__swiper-scrollbar-drag {
    height: 100%;
    position: relative;
    left: 0;
    top: 0;
    background: #222;
  }
`

/**
 * 配置 SBC Swiper
 * 
 * @template D data item type parameter
 * @template S info summary type parameter
 * @param {{
 *   sbcInfoSummary: import('vue').Ref<S>
 *   sceneData: import('vue').Ref<Record<string, unknown>>,
 *   slidesData: import('vue').Ref<D[]>
 *   virtualData: import('vue').Ref<D[]>
 *   gridCount: import('vue').Ref<number>
 *   debugRecorder?: import('@modules/homepage/utils/debug-print').DebugRecorder
 * }} context
 * @param {{
 *   injectStyles?: string[],
 *   enableScrollBar?: boolean,
 *   scrollBarHorizontalClass?: string,
 *   scrollBarDragClass?: string,
 *   classNamePrefix?: string,
 *   onReturnVisible?: () => void,
 * }} options
 */
export function useSbcSwiper({
  sceneData,
  slidesData,
  virtualData,
  sbcInfoSummary,
  gridCount,
  debugRecorder,
}, {
  injectStyles = [],
  enableScrollBar = false,
  scrollBarHorizontalClass = '',
  scrollBarDragClass = '',
  classNamePrefix = 'category_recommend_dynamic',
  onReturnVisible,
} = {}) {
  const {
    PUBLIC_CDN,
    GB_cssRight: cssRight, 
  } = deriveFromGlobalCommonInfo()

  const swiperContainerClassName = `${classNamePrefix}__swiper`

  const swiperTimer = ref(null)
  const obInstance = ref(null)
  const curTranslate = ref()
  const finalTranslate = ref()

  // Swiper 实例 模板 Ref
  const swiperInstanceRef = ref(null)

  // 只自动滑动一次（用户操作后停止）
  const isFirstAutoPlay = ref(true)

  // 曝光标志是否由自动滑动触发
  const isAutoPlayExpose = ref(false)
  
  // 自动滑动是否开启
  const isAutoPlayStarted = ref(false)
  const isAutoPlayEnded = ref(false)
  const isPausedBySliderMove = ref(false)

  // 全局弹窗
  const firstGlobalPopupShow = ref(false)
  const globalPopup = ref(null)
  const isTaped = ref(false)
  const isPausedByGlobPopup = ref(false)

  //开始滑动时间节点
  const slideTimerStartTime = ref(null)
  const slideTimerRemainTime = ref(null)
  
  // 销毁情况需要等待初始化
  const resumeAutoPlayAfterDestroy = ref(false)

  const swiperSlideWidth = computed(() => (
    `calc(100% / ${sbcInfoSummary.value.spacing})`
  ))

  const {
    columnCount,
    spacing,
    isAutoSlide = false,
    sbcAutoSlide = false,
    sbcSlideSpeed = 10,
  } = sbcInfoSummary.value || {}


  // 监视 globalPopup 的变化
  watch(globalPopup, (newVal) => {
    // result.endModel 弹窗全部弹完
    if (newVal.endModel && firstGlobalPopupShow.value) {
      if (isAutoSlide) {
        if(isAutoPlayStarted.value) {
          isPausedByGlobPopup.value = true
          pauseAutoPlay()
        } else {
          // stopAutoPlay()
          isFirstAutoPlay.value = true
        }

        nextTick(() => {
          setTimeout(() => {
            if(!isAutoPlayStarted.value) {
              swiperRunAutoPlay()
            } else {
              if(isPausedByGlobPopup.value) {
                resumeAutoPlay()
                isPausedByGlobPopup.value = false
              }
            }
          }, 2000)
        })
      }
    }
  }, { deep: true })

  // 监视 firstGlobalPopupShow 的变化
  watch(firstGlobalPopupShow, (newVal) => {
    if (newVal) {
      nextTick(() => {
        isPausedByGlobPopup.value = true
        pauseAutoPlay()
      })
    }
  })

  const swiperDir = cssRight ? 'rtl' : 'ltr'
  const swiperContainerStyle = computed(() => {
    const styl = {}
    if (columnCount === 4) {
      styl.paddingLeft = '0'
    }
    
    return styl
  })
  
  const swiperRunAutoPlay = () => {
    const isHomePage = sceneData.value.pageFrom === 'homePage'
    const isCampaignsPage = sceneData.value.pageFrom === 'campaigns'
    const topHide = isHomePage ? 88 : 44
    const bottomHide = isHomePage ? 47 : 0
    obInstance.value = new expose({ 
      observeHide: true, 
      exposeRatio: 0.99, 
      mask: [topHide, '0', bottomHide, '0']
    })
    obInstance.value.observe(
      {
        elements: swiperInstanceRef.value,
        once: false,
      },
      ({ exposeDoms }) => {
        if (!swiperInstanceRef.value) return
        if (exposeDoms?.length || isCampaignsPage) {
          // 第一次进入视口时候，等待sbcAutoSlide秒后开始自动播放
          if (isFirstAutoPlay.value) {
            clearTimeout(swiperTimer.value)
            slideTimerStartTime.value = Date.now()
            swiperTimer.value = setTimeout(() => {
              // 有全局弹窗 未结束全局弹窗 => 不执行播放
              if(!(firstGlobalPopupShow?.value && !globalPopup?.value?.endModel)) {
                swiperStartAutoPlay(swiperInstanceRef.value?.swiper)
                isFirstAutoPlay.value = false
                isAutoPlayExpose.value = true
                isAutoPlayStarted.value = true
                slideTimerStartTime.value = null
                slideTimerRemainTime.value = null
              }
            }, slideTimerRemainTime.value ? slideTimerRemainTime.value : ~~sbcAutoSlide * 1000)
          } else {
            // 第一次开始播放后，再次进入视口时候，直接开始自动播放
            resumeAutoPlay()
          }
        } else {
          clearTimeout(swiperTimer.value)
          pauseAutoPlay()
        }
      },
    )
  }
  // eslint-disable-next-line no-unused-vars
  const stopAutoPlay = () => {
    // 设置超出边缘回弹速度
    const swiper = swiperInstanceRef.value?.swiper
    if (swiper?.params) {
      swiper.params.speed = 999999
    }
    isFirstAutoPlay.value = false
    if (obInstance.value) {
      // 用户操作后就停止自动滑动
      swiperStopAutoPlay(swiper)
      isAutoPlayExpose.value = false
      clearAutoPlay()
    }
  }
  const pauseAutoPlay = () => {
    // 自动播放是 transition-duration 控制的
    // 所以暂停播放只能先获取此刻的偏移量，再通过设置 translate 来实现
    const swiperInstance = swiperInstanceRef.value?.swiper
    if (
      !swiperInstance
      || !swiperInstance.isHorizontal
    ) return

    curTranslate.value = swiperInstance?.getTranslate()
    finalTranslate.value = swiperInstance?.translate
    swiperInstance?.setTranslate(curTranslate.value)
    swiperStopAutoPlay(swiperInstance)
  }
  const clearAutoPlay = () => {
    isAutoPlayStarted.value = false
    clearTimeout(swiperTimer.value)
    swiperStopAutoPlay(swiperInstanceRef.value?.swiper)
    // swiper 视口监听销毁
    obInstance.value?.destroy?.()
    obInstance.value = null
  }
  const resumeAutoPlay = () => {
    const remainTranslate = Math.abs(finalTranslate.value) - Math.abs(curTranslate.value)
    const remainTime = remainTranslate / Math.abs(finalTranslate.value) * (columnCount - 4) * (~~sbcAutoSlide * 1000)
    remainTime && swiperInstanceRef.value?.swiper?.slideNext(remainTime)
    swiperStartAutoPlay(swiperInstanceRef.value?.swiper)
  }

  // Swiper 初始化
  // Swiper 配置参数
  const getSwiperParams = () => {
    const isNeedScrollBar = enableScrollBar
      ? (Number(spacing) % 1 === 0)
      : false

    return {
      ...staticSwiperParams,

      modules: [
        Grid, Autoplay, FreeMode, Virtual,
        ...(isNeedScrollBar ? [Scrollbar] : []),
      ],
      scrollbar: (
        isNeedScrollBar ? {
          hide: false,
          horizontalClass: scrollBarHorizontalClass,
          dragClass: scrollBarDragClass,
          dragSize: 12,
        } : void 0
      ),
  
      // 每页显示的个数，第 5 张图漏出一点
      // 只有四列时，每行展示 4 个; 矩形固定 4.61
      slidesPerView: (
        columnCount === 4 
          ? 4
          : Number(spacing)
      ),
      injectStylesUrls: [
        PUBLIC_CDN + '/pwa_dist/libs/swiper/modules/grid-element.min.css',
      ],
      injectStyles: [
        swiperFreeModeWrapperStyle,
        ...(isNeedScrollBar ? [swiperScrollBarStyle({ gridCount, classNamePrefix })] : []),
        ...injectStyles,
      ],
      virtual: {
        slides: slidesData.value,
        renderExternal: data => {
          const { slides, from, to } = data
          const intToIdx = parseInt(to)
          // Tips: 设置临时数据是为了只更新一次响应式数据 virtualData, 避免多次更新
          let tempVirtualDataLength = virtualData.value.length
          const tempVirtualData = []
  
          if (
            tempVirtualDataLength >= slidesData.value.length 
            || tempVirtualDataLength > intToIdx
          ) {
            return
          }
  
          slides.forEach((item, index) => {
            if (from + index >= virtualData.value.length) {
              tempVirtualData.push(item)
              tempVirtualDataLength++
            }
          })
  
          virtualData.value.push(...tempVirtualData)
        }
      },
      speed: sbcSlideSpeed * 1000 * (2 / 9), // 单位ms，滑动时间xx秒一屏, 因为配置的和实际有出入，所以补偿2/9, 经验值
      on: {
        init: () => {
          
          swiperInstanceRef.value?.swiper?.off('resize')
          nextTick(() => {
            if(resumeAutoPlayAfterDestroy.value) {
              // resumeAutoPlay()
              swiperInstanceRef.value?.swiper?.setTranslate(finalTranslate.value + 60)
              resumeAutoPlayAfterDestroy.value = false
            }
            swiperStopAutoPlay(swiperInstanceRef.value?.swiper)
            if (enableScrollBar && isAutoSlide && isFirstAutoPlay.value) {
              swiperRunAutoPlay()
            }
          })
        },
        sliderMove: () => {
          // stopAutoPlay()
          isTaped.value = true
          isPausedBySliderMove.value = true
          pauseAutoPlay()
        },
        slideChangeTransitionEnd() {
          if(isAutoSlide && !isAutoPlayEnded.value) {
            isPausedBySliderMove.value = false
            resumeAutoPlay() // 用户停止手动滑动后恢复自动滑动
          }
        },
        tap: () => {
          isTaped.value = true
          pauseAutoPlay()
          // stopAutoPlay()
        },
        touchEnd: () => {
          finalTranslate.value = swiperInstanceRef.value?.swiper?.getTranslate()
          swiperInstanceRef.value?.swiper?.setTranslate(curTranslate.value)
          if(isAutoSlide && !isAutoPlayEnded.value) {
            resumeAutoPlay() // 用户停止手动滑动后恢复自动滑动
          }
        },
        reachEnd: () => {
          isAutoPlayStarted.value = false
          isAutoPlayEnded.value = true
        },
        beforeDestroy: () => {
          pauseAutoPlay()
        },
        click: () => {
          isTaped.value = true
          pauseAutoPlay()
          // stopAutoPlay()
        },
      },
    }
  }
  const swiperInit = () => {
    let swiperEl = swiperInstanceRef.value
    if (!swiperEl) {
      swiperEl = document.querySelector(`.${swiperContainerClassName}`)
      if (!swiperEl) return // 仍然找不到 swiper 容器，则退出
      swiperInstanceRef.value = swiperEl
    }

    Object.assign(swiperEl, getSwiperParams())
    swiperEl.initialize()
  }

  // Swiper 水合
  const isSwiperHydrated = ref(false)
  const onSwiperHydrated = async () => {
    isSwiperHydrated.value = true
    debugRecorder?.debugLog('SBC 组件 Swiper hydrated')
    await nextTick()
    swiperInit()
  }
  const initRenderCount = (
    sbcInfoSummary.value?.spacing
      ? Math.ceil(Number(sbcInfoSummary.value.spacing))
      : 5
  )

  const getWindowValue = () => {
    let count = 0
    const timer = setInterval(() => {
      if (count < 20) {
        const isHasPopModalShow = window._GB_PopUpModalEventCenter_?.isHasPopModalShow
        if (!isHasPopModalShow) {
          count++
        } else {
          firstGlobalPopupShow.value = true
          clearInterval(timer)
        }
      } else {
        clearInterval(timer)
      }
    }, 500)
  }

  onActivated(() => {
    if (enableScrollBar && isAutoSlide && isFirstAutoPlay.value) {
      swiperRunAutoPlay()
    } else {
      // 未开始自动滑 恢复 TODO 待测试效果
      if(isAutoSlide) {
        if(!isAutoPlayStarted.value) {
          // resumeAutoPlay()
          if(slideTimerRemainTime.value) {
            swiperRunAutoPlay()
          }
        } else {
          // 直接滑
          // 销毁了
          if(swiperInstanceRef.value?.swiper.destroyed) {
            resumeAutoPlayAfterDestroy.value = true
            return
          }
        }
        return
      }

      swiperInstanceRef.value?.swiper?.slideNext(0)
      swiperInstanceRef.value?.swiper?.slidePrev(0)
    }
  })
  onDeactivated(() => {
    let _translate = swiperInstanceRef.value?.swiper?.translate
    if (_translate && cssRight) {
      _translate = -_translate
    }
    // 未开始自动滑 暂停
    if(!isAutoPlayStarted.value) {
      const gap = (Date.now() - slideTimerStartTime.value)
      if(gap > 0) {
        slideTimerRemainTime.value = ~~sbcAutoSlide * 1000 - gap
      }
      clearAutoPlay()
    } else {
      // 销毁了
      if(swiperInstanceRef.value?.swiper.destroyed) {
        return
      }
      pauseAutoPlay()
    }
  })

  onBeforeMount(() => {
    cccxEventBus.on('ccc-global-popup-modal', (result) => {
      globalPopup.value = { ...result }
    })
  })

  onMounted(async () => {
    await nextTick()
    swiperInit()
    getWindowValue()
  })

  // 对 swiperInstanceRef 对应 DOM 元素设置观察器
  // 当期重新恢复可见时，重新初始化 swiper
  if (typeof IntersectionObserver !== 'undefined') {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          swiperInit()
          onReturnVisible?.()
        }
      })
    })
    onMounted(() => {
      if (swiperInstanceRef.value) {
        observer.observe(swiperInstanceRef.value)
      }
    })
    onUnmounted(() => {
      if (swiperInstanceRef.value) {
        observer.unobserve(swiperInstanceRef.value)
      }
      observer.disconnect()
    })
  }

  return {
    swiperContainerClassName,
    swiperInstanceRef,
    swiperContainerStyle,
    swiperDir,
    swiperSlideWidth,
    initRenderCount,
    isSwiperHydrated,
    swiperInit,
    swiperRunAutoPlay,
    onSwiperHydrated,
    isAutoPlayStarted,
  }
}
